// src/firebase.ts
import { FirebaseOptions, initializeApp } from "firebase/app";
import { getMessaging, getToken, GetTokenOptions, onMessage } from "firebase/messaging";

const initToken = async (onTokenReceived: (token: string) => void ) => {
  try {
    const swRegistration: ServiceWorkerRegistration = await navigator.serviceWorker.ready;
    const firebaseConfig: FirebaseOptions = {
      apiKey: "AIzaSyB0DWvRx1RTYXjE2JJpgsqeoMCECdPHFpA",
      authDomain: "cloudnotification-bbff4.firebaseapp.com",
      databaseURL: "https://cloudnotification-bbff4.firebaseio.com",
      projectId: "cloudnotification-bbff4",
      storageBucket: "cloudnotification-bbff4.appspot.com",
      messagingSenderId: "246016189235",
      appId: "1:246016189235:web:a0c387cdb49bf3e9885446"
    };

    const app = initializeApp(firebaseConfig);
    const messaging = getMessaging(app);

    const getTokenOptions: GetTokenOptions = {
      vapidKey: 'BCiMgUoko2fYb8VuEkeQvASYpaMKZPJpaebiQfZ80SqoEqPdLVl6e5xVMIj3ztcHPUdYbZSURoPZlM14Pi9PRl8',
      serviceWorkerRegistration: swRegistration
    };
    console.log('Getting token...');
    console.log('messaging:', messaging);
    console.log('getTokenOptions:', getTokenOptions);
    const token = await getToken(messaging, getTokenOptions);
    console.log('Token:', token);
    onTokenReceived(token);
  } catch (error) {
    console.error('Error getting token:', error);
  }
};

const registerOnMessage = () => {
  const firebaseConfig: FirebaseOptions = {
    apiKey: "AIzaSyB0DWvRx1RTYXjE2JJpgsqeoMCECdPHFpA",
    authDomain: "cloudnotification-bbff4.firebaseapp.com",
    databaseURL: "https://cloudnotification-bbff4.firebaseio.com",
    projectId: "cloudnotification-bbff4",
    storageBucket: "cloudnotification-bbff4.appspot.com",
    messagingSenderId: "246016189235",
    appId: "1:246016189235:web:a0c387cdb49bf3e9885446"
  };

  const app = initializeApp(firebaseConfig);
  navigator.serviceWorker.ready.then((swRegistration) => {
    console.log('getting subscription...');
    swRegistration.pushManager.getSubscription().then((subscription) => { console.log('subscription:', subscription); });
  });
  const messaging = getMessaging(app);
  onMessage(messaging, (payload) => {
    console.log('Message received. ', payload);
    new Notification('PWA msg', {
      body: payload.notification?.body,
    });
  });
};

export { initToken, registerOnMessage };