import { useEffect, useState } from 'react';
import { initToken, registerOnMessage } from './firebase-messaging';
import logo from './logo.svg';
import './App.css';

const requestPermission = async () => {
  try {
    console.log('Requesting permission...');
    await Notification.requestPermission();
  } catch (error) {
    console.error('Error requesting permission:', error);
  }
};

const App = () => {
  const isNotificationSupported = 'Notification' in window;
  const [isGranted, setGranted] = useState(isNotificationSupported ? window.Notification.permission === 'granted' : false);
  const [swState, setSwState] = useState<ServiceWorkerRegistration | null>(null);
  const [receivedToken, setToken] = useState<string | null>(null);
  const isSuportSending = swState != null && 'showNotification' in swState;

  useEffect(() => {
    navigator.serviceWorker.ready.then((registration) => {
      console.log('Service worker ready:', registration);
      setSwState(registration);
    });
  }, []);

  useEffect(() => {
    if (isGranted) {
      initToken((newToken) => {
        console.log('Token:', newToken);
        setToken(newToken);
      });
      registerOnMessage();
    }
  }, [isGranted]);

  const onButtonClick = () => {
    requestPermission().then(() => {
      console.log('update permission ui-state.');
      setGranted(window.Notification.permission === 'granted');
    });
  };

  const sendNotification = () => {
    if (!isSuportSending) return;
    swState?.showNotification('PWA msg', {
      body: 'This is a test message.'
    });
  };

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        {
          isNotificationSupported ? null : <p>Notification is not supported</p>
        }
        {
          isNotificationSupported && isGranted ?
            <p>Permissiont is granted</p> :
            <button onClick={onButtonClick}>Request Permission</button>
        }
        {
          receivedToken ? (
            <div className="token">Token: {receivedToken}</div>
          ) : null
        }
        {
          isSuportSending && isGranted ?
            <button onClick={sendNotification}>Send Notification</button> : null
        }
      </header>
    </div>
  );
}

export default App;
